<template>
  <div id="company-in">
    <el-button
      size="small"
      type="primary"
      style="margin:20px 0 60px 10px;"
      @click="$router.go(-1)"
    >返回</el-button>
    <!-- <div style="margin-left:10px" v-if="dataIs">暂无企业数据，请添加企业</div> -->
    <el-form :inline="true" :model="companyData" class="demo-form-inline" style="margin-left:10px">
      <div v-for="(itemAll,i) in companies" :key="i">
        <el-form-item>
          <el-upload
            class="avatar-uploader"
            :action="URL.upLoad"
            :show-file-list="false"
            :on-success="res=>$set(itemAll,'images',res.data.path)"
            :before-upload="beforeAvatarUpload"
            :headers="uploadHeader"
            accept="image/jpeg, image/jpg, image/png"
          >
            <img v-if="itemAll.images" :src="itemAll.images" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <div class="prompt">
          <div>(仅上传1张图片)</div>
          <div>仅支持jpg、jpeg、png格式大小不能大于4M</div>
        </div>
        <div class="input-data">
          <el-form-item label="入驻企业数据" label-width="100px">
            <el-input v-model="itemAll.name" placeholder="请输入企业" clearable></el-input>
          </el-form-item>
          <el-form-item label="领域" label-width="80px">
            <el-select v-model="industries[i]" multiple placeholder="请选择">
              <el-option
                v-for="item in industryLabels"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onSubmit(itemAll,i)">确认</el-button>
            <i class="el-icon-close icon-size" @click="delSubmit(itemAll,i)"></i>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div>
      <img class="imgClick" width="30px" src="@/assets/images/添加.png" alt @click="addCompany" />
    </div>
  </div>
</template>
<script>
import { success, warning, error } from "@/utils/notification";
export default {
  data() {
    return {
      companyData: { images: "" },
      companies: [],
      industry_labels: [],
      industryLabels: [], //领域标签列表
      industries: [],
      uploadHeader: {}, //上传头部
      images: ""
      // dataIs: false
    };
  },
  created() {
    this.getCompanyList();
    this.getIndustry();
    let token = sessionStorage.adminToken; // pc前台token
    this.uploadHeader.Authorization = "Bearer " + token;
  },
  methods: {
    //确认添加修改入驻企业数据

    onSubmit(item, i) {
      let organ_id = sessionStorage.organizationId;
      // let images = item.images;
      if (!item.images) {
        warning("至少上传一张图片");
      } else if (!item.name) {
        warning("请输入企业名称");
      } else {
        if (item.id) {
          this.$http
            .post(this.URL.adminUrl.organAddEdit, {
              id: item.id,
              company_name: item.name,
              industry_labels: this.industries[i],
              organ_id: organ_id,
              images: item.images
            })
            .then(res => {
              console.log(res);
              this.getCompanyList();
              success("编辑成功");
            });
        } else {
          this.$http
            .post(this.URL.adminUrl.organAddEdit, {
              company_name: item.name,
              industry_labels: this.industries[i],
              organ_id: organ_id,
              images: item.images
            })
            .then(res => {
              console.log(res);
              this.getCompanyList();
              success("添加成功");
            });
        }
      }
    },

    //删除企业入驻
    delSubmit(item, i) {
      this.$confirm(" 是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.companies.splice(i, 1);
          this.$http
            .delete(this.URL.adminUrl.delCompany + item.id, {})
            .then(res => {
              this.getCompanyList();
              console.log(res);
            });
          success("删除成功");
        })
        .catch(() => {});
    },
    //点击添加企业输入框
    addCompany() {
      this.companies.push({});
      // this.dataIs = false;
    },
    //企业入驻--列表
    getCompanyList() {
      let id = sessionStorage.organizationId;
      this.$http.get(this.URL.adminUrl.organDetail + id, {}).then(res => {
        this.companies = res.data.data;
        const arr3 = this.companies.map(item => {
          let arr = [];
          item.industry_labels.map(bb => arr.push(bb.id));
          return arr;
        });
        this.industries = arr3;
      });
    },
    //获取领域数据
    getIndustry() {
      this.$http.get(this.URL.industry, {}).then(res => {
        this.industryLabels = res.data.data;
      });
    },

    //图片上传成功事件
    // onSuccess(res) {
    //   this.companyData.images = res.data.path;
    // },
    //图片上传前的事件
    beforeAvatarUpload(file) {
      var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt4M = file.size / 1024 / 1024 <= 4; //图片大小不超过4MB
      if (!testmsg) {
        error("上传图片格式不对!");
        return;
      }
      if (!isLt4M) {
        error("上传图片大小不能超过 4M!");
        return;
      }
      return testmsg && isLt4M;
    }
    //图片超出限制
    // handleExceed() {
    //   error("上传图片不能超过1张");
    // }
  }
};
</script>
<style lang="less">
#company-in {
  .prompt {
    font-size: 13px;
    display: inline-block;
    width: 100px;
    margin: 20px 20px 0 0;
    line-height: 1.3em;
  }
  .imgClick {
    cursor: pointer;
    margin-left: 400px;
  }
  .icon-size {
    margin-left: 5px;
    cursor: pointer;
  }
  .input-data {
    display: inline-block;
  }
}
.avatar-uploader .el-upload {
  border: 2px dashed #d9d9d9;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>